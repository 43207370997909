import React, {useState, useRef, useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid} from '@material-ui/core';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Portrait } from '@material-ui/icons';

import ThreeContainer from './ThreeContainer';
import ThreeJSComponent from './ThreeJSComponent';

import firebase from './firebase';

export default function ExhibitionTour({exhibitions, englishOn}) {

  
        // Define the Style for the breadcrumb 
        const useStyles = makeStyles((theme) => ({
            link: {
                display: 'flex',
            },
            icon: {
                marginRight: theme.spacing(0.5),
                width: 150,
                height: 150,
            },
            Button:{
                backgroundColor: 'rgb(131,131,131)'
            }
        }));


        // Define Variables
        let history         = useHistory(); // 
        let {id}            = useParams();
        let [tour, setTour] = useState({});
        const classes       = useStyles();

        const pExhibitions2        = firebase.firestore().collection('Exhibitions');
        const alovelaceDocumentRef = firebase.firestore().doc('Exhibitions/' + id);


        //
        function getExhibitionTour(id){
            const newExhibitions = [...exhibitions];
            const exhibit = newExhibitions.find(item => item.id === id);
            setTour(exhibit);
        }

        //
        useEffect(() => {
            getExhibitionTour(id);
        }, [exhibitions, tour])


        //
        function handleClick(event) {
            event.preventDefault();
            history.goBack();
        }

        function populateExhibit(){
            console.log("Generating Exhibit for " + id) 
           
            // db.collection("Exhibitions").add({
            //     first: "Ada",
            //     last: "Lovelace",
            //     born: 1815
            // })
            // .then((docRef) => {
            //     console.log("Document written with ID: ", docRef.id);
            // })
            // .catch((error) => {
            //     console.error("Error adding document: ", error);
            // });

            var pexhibit = firebase.firestore().collection("Exhibitions").doc(id);
            //console.log(pexhibit);

            pexhibit.update({
                cuadros: [
                    {
                        id: 0,
                        autor: "Goyo Domínguez",
                        titulo: "Rosa",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 55,
                        width: 46,
                        material_es: "Acrílico y Pan de Oro",
                        canvas: "Tabla",
                        imagen: "01-ROSA.jpg",
                        precio: 1500,
                        url: "https://lanzaroteartgallery.com/obra/rosa/",
                        wallid: 9,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 1,
                        autor: "Goyo Domínguez",
                        titulo: "Laúd",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 48,
                        width: 81,
                        material_es: "T. Mixta",
                        canvas: "Tabla",
                        imagen: "02-LAUD.jpg",
                        precio: 4000,
                        url: "https://lanzaroteartgallery.com/obra/laud/",
                        wallid: 1,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 2,
                        autor: "Alfredo Montaña",
                        titulo: "Mujer Con Abrigo Y Sombreros Rojos",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 130,
                        width: 97,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "03-AlfredoMontana.jpg",
                        precio: 7000,
                        url: "https://lanzaroteartgallery.com/obra/mujer-con-abrigo-y-sombrero-rojo/",
                        wallid: 2,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 3,
                        autor: "Alfredo Montaña",
                        titulo: "Reencuentro O Despedida",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 130,
                        width: 97,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "04-AlfredoMontana.jpg",
                        precio: 5500,
                        url: "https://lanzaroteartgallery.com/obra/reencuentro-o-despedida/",
                        wallid: 3,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 4,
                        autor: "Teresa Kodama",
                        titulo: "A Charmosa Avenida São João",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 120,
                        width: 100,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "05-ACharmosaAvenida.jpg",
                        precio: 1100,
                        url: "https://lanzaroteartgallery.com/obra/a-charmosa-avenida-sao-joao/",
                        wallid: 5,
                        offset: -2,
                        scalefactor: 1
                    },
                    {
                        id: 5,
                        autor: "Teresa Kodama",
                        titulo: "Praça João Mendes De Encantos",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 120,
                        width: 90,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "06-PraçaJoãoMendesdeEncantos.jpg",
                        precio: 1100,
                        url: "https://lanzaroteartgallery.com/obra/praca-joao-mendes-de-encantos/",
                        wallid: 5,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 6,
                        autor: "Milena Julianno",
                        titulo: "Fluidos",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 80,
                        width: 60,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "07-Fluidos.jpg",
                        precio: 750,
                        url: "https://lanzaroteartgallery.com/obra/fluidos/",
                        wallid: 5,
                        offset: 2,
                        scalefactor: 1
                    },
                    {
                        id: 7,
                        autor: "Milena Julianno",
                        titulo: "Fragmentos",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 80,
                        width: 60,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "08-Fragmentos.jpg",
                        precio: 350,
                        url: "https://lanzaroteartgallery.com/obra/fragmentos/",
                        wallid: 6,
                        offset: -2,
                        scalefactor: 1
                    },
                    {
                        id: 8,
                        autor: "Vera Reichert",
                        titulo: "Trama I & II",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 120,
                        width: 80,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "09-Trama.jpg",
                        precio: 800,
                        url: "https://lanzaroteartgallery.com/obra/trama-iii/",
                        wallid: 6,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 9,
                        autor: "Vera Reichert",
                        titulo: "Foto de Superficie3",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 50,
                        width: 70,
                        material_es: "Fotografía",
                        canvas: "Papel",
                        imagen: "10-FotoSuperficie3.jpg",
                        precio: 250,
                        url: "https://lanzaroteartgallery.com/obra/superficie-3/",
                        wallid: 6,
                        offset: 2,
                        scalefactor: 1
                    },
                    {
                        id: 10,
                        autor: "Carmen Espinosa Soto",
                        titulo: "El Cigarro",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 83,
                        width: 73,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "11-Cigarro.jpg",
                        precio: 750,
                        url: "https://lanzaroteartgallery.com/obra/02-cigarro/",
                        wallid: 18,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 11,
                        autor: "Annabel Gonzalez",
                        titulo: "Julia Contempla El Mar",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 50,
                        width: 40,
                        material_es: "Fotografía",
                        canvas: "BARYTA PRESTIGE 340 g/m2",
                        imagen: "12-JuliaContemplaElMar.jpg",
                        precio: 900,
                        url: "https://lanzaroteartgallery.com/obra/julia-contempla-el-mar/",
                        wallid: 19,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 12,
                        autor: "Annabel Gonzalez",
                        titulo: "Pasajeros La Terminal",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 70,
                        width: 100,
                        material_es: "Fotografía",
                        canvas: "BARYTA PRESTIGE 340 g/m2",
                        imagen: "13-Pasajeros.jpg",
                        precio: 600,
                        url: "https://lanzaroteartgallery.com/obra/terminal/",
                        wallid: 16,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 13,
                        autor: "Cecilia Escandon Román",
                        titulo: "Aqua",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 50,
                        width: 40,
                        material_es: "Fotografía",
                        canvas: "Papel Hahnememuhle",
                        imagen: "14-AquaPapelHahnememuhle.jpg",
                        precio: 800,
                        url: "https://lanzaroteartgallery.com/obra/aqua/",
                        wallid: 17,
                        offset: 0,
                        scalefactor: 2.5
                    },
                    {
                        id: 14,
                        autor: "Cecilia Escandon Román",
                        titulo: "Elisa",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 40,
                        width: 50,
                        material_es: "Fotografía",
                        canvas: "Papel Hahnememuhle",
                        imagen: "15-ElisaPapelHahnememuhle.jpg",
                        precio: 600,
                        url: "https://lanzaroteartgallery.com/obra/elisa/",
                        wallid: 14,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 15,
                        autor: "Fabio Hurtado",
                        titulo: "La Siesta III",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 65,
                        width: 92,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "16-LaSiesta3.jpg",
                        precio: 4500,
                        url: "https://lanzaroteartgallery.com/obra/la-siesta-iii/",
                        wallid: 15,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 16,
                        autor: "Fabio Hurtado",
                        titulo: "Metro",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 81,
                        width: 114,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "17-Metro.jpg",
                        precio: 7000,
                        url: "https://lanzaroteartgallery.com/obra/metro/",
                        wallid: 21,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 17,
                        autor: "Masqali",
                        titulo: "Mariposa de Luz",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 100,
                        width: 100,
                        material_es: "T. Mixta",
                        canvas: "Lienzo",
                        imagen: "18-MariposaDeLuz.jpg",
                        precio: 1100,
                        url: "https://lanzaroteartgallery.com/obra/mariposa-de-luz/",
                        wallid: 20,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 18,
                        autor: "Federica Cappelletto",
                        titulo: "Memoria De Un Tiempo Pasado",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 65,
                        width: 92,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "19-MemoriaDeUnTiempoPasado.jpg",
                        precio: 800,
                        url: "https://lanzaroteartgallery.com/categoria-obra/artistas-tienda/federica-cappelletto-2/",
                        wallid: 7,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 19,
                        autor: "Federica Cappelletto",
                        titulo: "Equilibrio De Un Caos Ordenado",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 50,
                        width: 70,
                        material_es: "Acrílico",
                        canvas: "Lienzo",
                        imagen: "20-EquilibrioDeUnCaosOrdenado.jpg",
                        precio: 400,
                        url: "https://lanzaroteartgallery.com/categoria-obra/artistas-tienda/federica-cappelletto-2/",
                        wallid: 13,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 20,
                        autor: "José Carlos Córdoba",
                        titulo: "Rimbaud",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 70,
                        width: 50,
                        material_es: "Óleo",
                        canvas: "Tabla",
                        imagen: "21-Rimbaud.jpg",
                        precio: 950,
                        url: "https://lanzaroteartgallery.com/obra/14-rimbaud/",
                        wallid: 12,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 21,
                        autor: "Charles Tawil",
                        titulo: "Exibição",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 62,
                        width: 100,
                        material_es: "Tinta Esmalte",
                        canvas: "Metracrilato",
                        imagen: "22-Exibição.jpg",
                        precio: 600,
                        url: "https://lanzaroteartgallery.com/obra/exibicao/",
                        wallid: 8,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 22,
                        autor: "Charles Tawil",
                        titulo: "Artificios",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 64,
                        width: 100,
                        material_es: "Tinta Esmalte",
                        canvas: "Metracrilato",
                        imagen: "23-Artifícios.jpg",
                        precio: 600,
                        url: "https://lanzaroteartgallery.com/obra/artificios/",
                        wallid: 11,
                        offset: 3/100,
                        scalefactor: 1
                    },
                    {
                        id: 23,
                        autor: "Masqali",
                        titulo: "Mirando Al Cielo I",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 75,
                        width: 75,
                        material_es: "T. Mixta",
                        canvas: "Lienzo",
                        imagen: "24-MirandoAlCielo.jpg",
                        precio: 700,
                        url: "https://lanzaroteartgallery.com/obra/mirando-al-cielo-i/",
                        wallid: 10,
                        offset: 0,
                        scalefactor: 1
                    },

                    {
                        id: 24,
                        autor: "José Carlos Córdoba",
                        titulo: "Jcc_Il Était Une Fois...",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 70,
                        width: 50,
                        material_es: "T. Mixta",
                        canvas: "Lienzo",
                        imagen: "25-JCC_2_était_une_fois.jpg",
                        precio: 950,
                        url: "https://lanzaroteartgallery.com/obra/02-jcc_il-etait-une-fois/",
                        wallid: 4,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 25,
                        autor: "Alfredo Montaña",
                        titulo: "Rostros con Sombreros",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 130,
                        width: 162,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "05-AlfredoMontana.jpg",
                        precio: 24000,
                        url: "https://lanzaroteartgallery.com/obra/rostros-con-sombreros/",
                        wallid: 20,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 26,
                        autor: "Alfredo Montaña",
                        titulo: "Incidente Inesperado",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 146,
                        width: 114,
                        material_es: "Óleo",
                        canvas: "Lienzo",
                        imagen: "06-AlfredoMontana.jpg",
                        precio: 8000,
                        url: "https://lanzaroteartgallery.com/obra/incidente-inesperado-2/",
                        wallid: 2,
                        offset: 0,
                        scalefactor: 1
                    },
                    {
                        id: 27,
                        autor: "Alfredo Montaña",
                        titulo: "El Mantón Asimetria cromatica",
                        descripcion: "Lorem ipsum dolor sit amet consectetur adipiscing elit pharetra luctus morbi risus senectus, netus cursus sollicitudin sem consequat class a vestibulum faucibus aenean quisque.",
                        height: 200,
                        width: 150,
                        material_es: "Óleo",
                        canvas: "Tela",
                        imagen: "07-AlfredoMontana.jpg",
                        precio: 45000,
                        url: "https://lanzaroteartgallery.com/obra/el-manton-asimetria-cromatica/",
                        wallid: 2,
                        offset: 0,
                        scalefactor: 1
                    },
                    ]
            })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

            
        }


    return (
        <section>
         
            { !tour ? 
            (
                // <div className="containerViewportSize containerErrorExhibit">
                //     <span> <b>Ha habido un error. La exposición no se ha podido leer del servidor o esta no existe.</b> </span>
                // </div>

                 <div className="nofoundContainer">
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <h1><Portrait className={classes.icon}/></h1>
                            <h2>UPS! Ha habido un error.</h2>
                            <p>La exposición que busca no existe, o no se ha podido leer del servidor.
                            Pero puede hacer clic en el botón de abajo para volver a la página de inicio.
                            </p>
                            <Button type="button" 
                                    variant="contained"  href="/"  
                                    className={classes.Button}
                                    startIcon={<AccountBalanceIcon />}> 
                                    Ir a Casa
                            </Button>  
                        </Grid>
                    </Grid>
                </div>
            ):(
                <>
                    <div className="section_title"> 
                        <span> <b>{tour.title} </b></span> 
                    </div>
                
                    {/* <ThreeContainer exhibit = { exhibitions.find(item => item.id === id) } englishOn = {englishOn}/> */}
                    <ThreeJSComponent exhibit = { exhibitions.find(item => item.id === id) } englishOn = {englishOn}/>
                
                </>
            )}
        </section>
    )
}
