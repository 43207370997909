/**
 * TODO:
 * - clean up the code deleting all debug statements 
 * 
 * ? NOTE: Here you have a list of tags that you can use on the code
 */ 

 //   todo
 //   ? info
 //   ! warning
 //   * hola 
 //   debug 


import * as THREE from "three";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders//RGBELoader';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper';

import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

import AppEnums from './AppEnums';

// Camera Controls
import { WalkControls } from './library/WalkControls';
import { WalkControlsDrag } from './library/WalkControlsDrag';
import * as TWEEN from '@tweenjs/tween.js';
import { ChatOutlined, TrackChangesRounded } from "@material-ui/icons";
import { NoToneMapping } from "three";


export default (container, exhibit) => {

    let clock;    // Three JS Timer  
    let scene;    // ThreeJS Scene Manager
    let renderer; // Three Scene Renderer 
    let camera; 
    let walkControl;
    let labelRenderer;
    let composer;
    let renderPass;
    let saoPass;
    let width;
    let height;
    let canvas;
    let videoDiv;
    let divBlocker;
    let divLoading;
    let divInstructions;
    let divPanel;
    let divPanelInfo;

    let referenceHeightCanva  = 1.65;
    let referenceHeightCamera = 1.65;

    let hasTourEnded = false;

    const pathAssets = process.env.PUBLIC_URL + '/assets/';
    const pathMedia  = process.env.PUBLIC_URL + '/assets/media/';
    const pathModels = process.env.PUBLIC_URL + '/assets/models/';
    const pathCanvas = process.env.PUBLIC_URL + '/assets/cuadros/';

    // For Camera Collision System
    let raycaster     = new THREE.Raycaster();
    let sceneCollision = new Array()

    // For Setting the portraits 
    let raycasterMouse;
    let line;
    const intersectionMouse = {
        intersects: false,
        point:  new THREE.Vector3(),
        normal: new THREE.Vector3()
    };
    const mouse = new THREE.Vector2();
    const intersectsMouse = [];
    let mouseHelper = undefined;
    let moved      = false;

    let meshCollision = undefined;

    // Beach Scene
    let water;
    let sun;


    let canvasList = undefined; // List of the canvas of the exhibit

    // Debug: Testing Global Constansts
    // wallsList.forEach(wall => {
    //     console.log("Pared: " + wall.name);
    // });
    //console.log(AppEnums.wallsList);

    
    // Canvas Selection System
    let raycasterSelection;
    const mouseSelection = new THREE.Vector2();
    let groupCuadros;   
    let clicked = false;


    // Debugging Flags
    let dbgLogOutProcesses = false;


    // DEBUG Delete to clean up the code
    // GUI parameters
    let params = {
        enablePan: true,
        enableDamping: true,
        nofly: true,
        lookVertical: true,
        constrainVertical: true,
        heightSpeed: false
      };

    // DEBUG: 
    // console.log("Initializing Virtual Gallery for: " );
    // console.log("Exhibit: ");
    // console.log(exhibit);

    // Grab Containers
    videoDiv         = document.querySelector('#viewport');
    divBlocker       = document.querySelector('#blocker');
    divLoading       = document.querySelector('#divLoading');
    divInstructions  = document.querySelector('#divInstructions');
    divPanel         = document.getElementById('ThreeContainer');
    divPanelInfo     = document.getElementById('CanvasInfo');

    // Create the Clock
    clock = new THREE.Clock();

    // ? Should i remove this? I think it is not necesary 
    width  = window.innerWidth  - 100;
    height = window.innerHeight - 200;

    // Debug
    let timeStart = performance.now();
    let timeEnd   = null;

    // Create the Canvas inside the container
    canvas = createCanvas(document, container, width, height);
    canvas.hidden = true;

    // Create the Scene
    scene = createScene();


    // Create the Camera
    camera = createCamera(canvas.width, canvas.height);


    // Create the Renderer
    renderer = createRenderer(canvas, canvas.width, canvas.height);


    // Create the Camera Control: Modified First Person Control
    walkControl = createCameraControl(camera, renderer);
    walkControl.activeLook = false;
    walkControl.SetAzimuth(90);

    // Create Camera Collision System
    createCameraCollision(camera, walkControl, scene, sceneCollision);

    // Create all the extra elements of the scene (no modelled ones)
    createSceneExtra(scene);


    // Load the modelled models into the scene
    createSceneGallery(scene, renderer);  


    createSceneBeach(scene);


    // Create the Canvas for the exhibit
    canvasList = populateExhibit(exhibit.exhibit);


    // Create Dummy Scene
    //createDummyScene();

    // Create Mouse Normal Picker
    //createMousePicking(walkControl);

    // Initiate the Canvas Selection to popup the info panel
    window.addEventListener("mousemove", mouseMoveSelect, false);
    window.addEventListener("mousedown", (ev)=>{ 
        clicked = true;  
        var pPanel = document.getElementById('CanvasInfo');
        if(selectedObject){
            toggleInfoPanel(pPanel, selectedObject);
        } 
        
        // Debug
        // console.log("Clicked:  True")
        //checkIntersection( ev.clientX, ev.clientY );

    } );
    window.addEventListener("mouseup", (ev)=>{ 
        clicked = false; 
        // Debug
        // console.log("Clicked:  false")
    } );


    // Bind The events 
    bindEventListeners();


    // Set the click callback for the Navigation Instruction container
    // It will start the tour by moving the camera into the gallery
    // and enable camera control
    divInstructions.addEventListener( 'click', bindStartTourListener)


    // Add the Info Panel Button Click Callback function
    document.getElementById("btnClose").addEventListener("click", function() {
        hideInfoPanel(divPanelInfo);
    });


    // Set that canvas is only render when the page is 
    // visible or focus
    RunOnDisplayOnly();


    // Render the scene
    render();


    //=========================================================================/
    //  M  E  T  H  O  D  S 
    //=========================================================================/

    function setLanguage(){
        console.log("Changing language")
    }
 
 
    /**
     * Create the Canvas for the ThreeJS context
     * @param {*} document 
     * @param {*} container 
     */
    function createCanvas(document, container, width, height){

        // Create the Canvas
        const canvas = document.createElement('canvas');     

        // Add the Canvas to the Container Element
        container.appendChild(canvas);
        // canvas.style.width  = '100%';
        // canvas.style.height = '100%';
        // canvas.style.width  = `${width}px`;
        // canvas.style.height = `${height}px`;
        canvas.style.width  = '66vh';
        canvas.style.height = '84vh';

        // Return the created canvas
        return canvas;
    }


    /**
     * 
     */
    function createScene(){
        const scene = new THREE.Scene();
        scene.background = new THREE.Color("#000000");
        return scene;
    }


    /**
     * 
     * @param {*} canvas 
     * @param {*} width 
     * @param {*} height 
     * @returns 
     */
    function createRenderer(canvas, width, height ) {

        const renderer = new THREE.WebGLRenderer({ canvas: canvas, 
                                                   antialias: true,
                                                   alpha: true }); 
        const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
        renderer.setPixelRatio(DPR);
        renderer.setSize(width, height);

        // Set Parameters
        renderer.shadowMap.enabled = true;    
        renderer.shadowMap.type    = THREE.PCFSoftShadowMap;
      
        renderer.physicallyCorrectLights = true;

        // Todo: Check if those parameters are not deprecated. 
        // renderer.gammaInput        = true;
        //renderer.gammaOutput       = false; 
        //renderer.toneMapping = THREE.ACESFilmicToneMapping; //THREE.ReinhardToneMapping; 
        renderer.toneMappingExposure = 2;
        renderer.outputEncoding = THREE.GammaEncoding;
        renderer.outputEncoding = THREE.sRGBEncoding;

        return renderer;
    }


    /**
     * 
     * @param {*} width 
     * @param {*} height 
     * @returns 
     */
    function  createCamera(width, height ) {
        const aspectRatio = width / height;
        const fieldOfView = 75;
        const nearPlane   = 0.1;
        const farPlane    = 1000; 
        const camera = new THREE.PerspectiveCamera(fieldOfView, 
                                                   aspectRatio, 
                                                   nearPlane, 
                                                   farPlane);
        camera.position.set( 5.816012029169941, 
                            referenceHeightCamera, 
                            2.528875540357396); // Outside in front of the door
        camera.rotation.set(-0.06451100897480591, 
                            -0.011975041025625454, 
                            -0.0007735767833202102)
        
        return camera;
    }


    /**
     * Create and asociate the camera to a first person walking camera control 
     * 
     * This camera control force the camera to be at certain height (Average Human 
     * eye height of 1.7 m)
     * 
     * @param {*} camera 
     * @param {*} renderer 
     * @returns 
     */
    function createCameraControl(camera, renderer){
        // Create Walk Camera Control
        let walkControl = new WalkControls(camera, renderer.domElement );
        walkControl = new WalkControlsDrag( camera, renderer.domElement );
        walkControl.movementSpeed = 1;
        walkControl.lookSpeed     = 0.15;  // 0.2;
        walkControl.update(clock.getDelta());
        walkControl.enablePan     = params.enablePan;  // true;
        walkControl.enableDamping = true; //params.enableDamping;  // false;
        walkControl.noFly         = params.nofly; // true;
        walkControl.lookVertical  = params.lookVertical; //true;
        walkControl.constrainVertical = params.constrainVertical; //true;
        walkControl.verticalMin   = 80;  // Min start from Up vector 0º 
        walkControl.verticalMax   = 105; // Max end at 180º from Up Vector
        walkControl.heightSpeed   = params.heightSpeed; //true;
        walkControl.heightMin     = referenceHeightCamera;
        walkControl.heightMax     = referenceHeightCamera;
        walkControl.activeLook    = false;
        
        return walkControl;
    }


    /**
     * Create a 4-direction Collistion System for the Camera movement with 
     * the scene.
     * 
     * @param {*} camera 
     * @param {*} scene 
     */
    function createCameraCollision(camera, cameraController, scene, sceneCollision){

        let flagDebug = false;

        // Sanity Check
        if(!raycaster)
            raycaster = (raycaster == null ? new THREE.Raycaster() : raycaster);
        
        //const sceneCollision   = new Array()
        let intersects      = new Array()
        var cameraPostion   = new THREE.Vector3();
        var cameraDirection = new THREE.Vector3();
        camera.getWorldPosition(cameraPostion);
        camera.getWorldDirection(cameraDirection);

        // For Debugging
        
        let arrowHelper = new THREE.ArrowHelper(cameraDirection, cameraPostion, 1,  0xff0000  );
        if(flagDebug) 
            scene.add(arrowHelper);

        // Add the Collision Check to the Camera Controller
        cameraController.addEventListener('change', function (e) { 
            
            // Get Camera's Position and Direction
            camera.getWorldPosition(cameraPostion);
            camera.getWorldDirection(cameraDirection);

            // For Debugging 
            if(flagDebug){
                scene.remove ( arrowHelper );
                arrowHelper = new THREE.ArrowHelper( cameraDirection, cameraPostion, 1, 0xff0000 );
                scene.add( arrowHelper );
            }
            
            // --- C O M P U T E    F O R W A R D   C O L L I S I O  N S  ----

            if(this.moveForward)
            {
                // Set the Raycaster with the current camera situation
                raycaster.set( cameraPostion, cameraDirection );

                // Calculate the intersections 
                intersects = raycaster.intersectObjects( sceneCollision );

                // Sanity Check: No intersections, then stop the process
                if(intersects.length === 0)
                return;
            
                // Check if the Camera is too close to the intesected point
                if(intersects[0].distance < cameraController.target.distanceTo(camera.position)){
                camera.position.setX(cameraController.prevCameraPosition.x);
                camera.position.setZ(cameraController.prevCameraPosition.z);
                }
            }

            // --- C O M P U T E    B A C K W A R D   C O L L I S I O  N S  ---
            
            if(this.moveBackward)
            {
                // Set the Raycaster to test backward intersections
                raycaster.set( cameraPostion, cameraDirection.negate() );

                // Calculate the intersections 
                intersects = raycaster.intersectObjects( sceneCollision );

                // Sanity Check: No intersections, then stop the process
                if(intersects.length === 0)
                return;
            
                // Check if the Camera is too close to the intesected point
                if(intersects[0].distance < cameraController.target.distanceTo(camera.position)){
                camera.position.setX(cameraController.prevCameraPosition.x);
                camera.position.setZ(cameraController.prevCameraPosition.z);
                }
            }
            
            // --- C O M P U T E    L E F T W A R D   C O L L I S I O  N S  ---
            
            if(this.moveLeft)
            {

                // Set the Raycaster with the current camera situation
                camera.getWorldDirection(cameraDirection);

                // Set the Raycaster to test left intersections
                raycaster.set( cameraPostion, cameraDirection.cross(new THREE.Vector3(0,-1,0)) );
            
                // Calculate the intersections 
                intersects = raycaster.intersectObjects( sceneCollision );

                // Sanity Check: No intersections, then stop the process
                if(intersects.length === 0)
                return;
            
                // Check if the Camera is too close to the intesected point
                if(intersects[0].distance < cameraController.target.distanceTo(camera.position)){
                camera.position.setX(cameraController.prevCameraPosition.x);
                camera.position.setZ(cameraController.prevCameraPosition.z);
                }
            }

            //-- C O M P U T E     R I G H T W A R D   C O L L I S I O  N S  --
            
            if(this.moveRight)
            {
                // Set the Raycaster with the current camera situation
                camera.getWorldDirection(cameraDirection);
        
                // Set the Raycaster to test right intersections
                raycaster.set( cameraPostion, cameraDirection.cross(new THREE.Vector3(0,1,0)) );
                
                // Calculate the intersections 
                intersects = raycaster.intersectObjects( sceneCollision );
        
                // Sanity Check: No intersections, then stop the process
                if(intersects.length === 0)
                return;
            
                // Check if the Camera is too close to the intesected point
                if(intersects[0].distance < cameraController.target.distanceTo(camera.position)){
                camera.position.setX(cameraController.prevCameraPosition.x);
                camera.position.setZ(cameraController.prevCameraPosition.z);
                }
            }
        });

    } // End createCameraCollision()


    let materialList = new Map();
    let envMapPlaya = null;

    /**
     * 
     * @param {*} scene 
     * @param {*} renderer 
     */
    function createSceneGallery(scene, renderer){

        
        const pmremGenerator2 = new THREE.PMREMGenerator( renderer );
        pmremGenerator2.compileEquirectangularShader();

        // Load the HDRI Enviorement Map
        const rgbeloader2 = new RGBELoader()
             .setDataType( THREE.UnsignedByteType )
             .setPath( pathMedia )
             .load( 'playa3.hdr', function ( texture ) {
 
                envMapPlaya = pmremGenerator2.fromEquirectangular( texture ).texture;
                scene.background  = envMapPlaya;
                 
                texture.dispose();
                pmremGenerator2.dispose();

                    // TODO I comment this to refresh the viewport just after loaded the whole scene
                //renderer.render( scene, camera );

                // use of RoughnessMipmapper is optional
                const roughnessMipmapper = new RoughnessMipmapper( renderer );

                // Create the GLTF Loader and point to the models directory
                const loader = new GLTFLoader().setPath( pathModels );

                                                
                // Load the Virtual Gallery
                loader.load('galeria_doors.glb', gltf => {

                    // Traverse the Children of the loaded galeria scene 
                    // to do any operations that we need 
                    gltf.scene.traverse( function ( child ) {

                        // Add the Material fo the list
                        let temp = child.material;
                        
                        if( temp !== undefined )
                        {
                            if(!materialList.has(temp.name))
                                materialList.set(temp.name, child.material);

                            //console.log("Inside Material");

                            // if(temp.name.includes('Glass')){
                            if(temp.name === 'Glass' ){
                                
                                //console.log(child);

                                child.material.transparent= true;
                                child.material.side= THREE.FrontSide;
                                child.material.blending= THREE.AdditiveBlending;  
                                child.material.blending= THREE.NormalBlending;  
                                child.material.depthWrite= false;
                                child.material.refractionRatio= 0.9;
                                child.material.combine= THREE.MixOperation; 
                                // child.material.metalness    = 0.1;
                                // child.material.roughness    = 0.2;
                                child.material.opacity = 0.5;
                                child.material.reflectivity = 1.0;
                       
                                child.material.envMap = envMapPlaya;
                                child.material.envMapIntensity = 0.3;
                                child.material.transmission = 0.6

                                child.material.reflectivity= 0.6
                                child.material.refractionRatio= 0.84
                                child.material.roughness= 0.15

                                child.material.needsUpdate = true;
                               
                            }

                            if(temp.name === 'Glass_Fake'){
                                child.material.envMap= envMapPlaya;
                                child.material.envMapIntensity = 0.8;
                                child.material.combine= THREE.MixOperation; 
                                child.material.needsUpdate = true;

                            }
                        }
                
                        // For Any Mesh Chold
                        if ( child.isMesh ) {

                             // ? TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
                            roughnessMipmapper.generateMipmaps( child.material );

                            //child.material.envMapIntensity = 1.2;
                            // For Lighting
                            child.receiveShadow = false;
                            child.castShadow    = false;
                        }

                    } );

                    gltf.scene.name = "gallery_doors";
                    scene.add( gltf.scene );

                    roughnessMipmapper.dispose();

                    // Refresh the Canvas
                    resizeCanvas();
                    renderer.render( scene, camera );

                } ); // End Load the Gallery Model


        })



        const pmremGenerator = new THREE.PMREMGenerator( renderer );
        pmremGenerator.compileEquirectangularShader();

        // Load the HDRI Enviorement Map
        const rgbeloader = new RGBELoader()
            .setDataType( THREE.UnsignedByteType )
            .setPath( pathMedia )
            .load( 'teguise.hdr', function ( texture ) {

                const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
            
                //scene.background  = envMap;
                scene.environment = envMap;

                texture.dispose();
                pmremGenerator.dispose();

                // TODO I comment this to refresh the viewport just after loaded the whole scene
                //renderer.render( scene, camera );

                // use of RoughnessMipmapper is optional
                const roughnessMipmapper = new RoughnessMipmapper( renderer );

                // Create the GLTF Loader and point to the models directory
                const loader = new GLTFLoader().setPath( pathModels );
                                
                // Load the Virtual Gallery
                loader.load('galeria.glb', gltf => {

                    // Traverse the Children of the loaded galeria scene 
                    // to do any operations that we need 
                    gltf.scene.traverse( function ( child ) {

                        // if(child.name.includes('Floor')){
                        //     child.material.toneMapped = false;
                        //     child.material.envMapIntensity = 1;
                        //     child.material.environment = null;
                        //     child.material.needsUpdate = true;
                        // }

                        
                        if(child.type==="PointLight")
                        {
                            child.castShadow = false; // default false
                            child.color = new THREE.Color(0xFFF7D2);
                            child.intensity = 0.5;
                            child.decay = 0.6;
                            child.distance = 3.6;
                            //Set up shadow properties for the light
                            // child.shadow.mapSize.width  = 1024*0.5; // default
                            // child.shadow.mapSize.height = 1024*0.5; // default
                            // child.shadow.camera.near    = 0.5; // default
                            // child.shadow.camera.far     = 50; // default
                            // console.log(child)
                        }

                        // Add the Material fo the list
                        let temp = child.material;
                        
                        if( temp !== undefined )
                        {
                            if(!materialList.has(temp.name))
                                materialList.set(temp.name, child.material);

                            temp.environment = envMap;
                            temp.needsUpdate = true;

                            if(temp.name.includes('Glass')){
                                child.material.transparent= true;
                                child.material.side= THREE.FrontSide;
                                child.material.blending= THREE.AdditiveBlending;  
                                child.material.blending= THREE.AdditiveBlending;  
                                child.material.depthWrite= false;
                                //child.material.combine= THREE.MixOperation; 
                                child.material.reflectivity= 0.01;
                                //child.material.environment = envMap;
                                // if(child.material.type.equal('MeshPhysicalMaterial'))
                                //     child.manterial.metalness = 0.1;
                                // console.log(child.material);
                                child.material.environment = envMapPlaya;
                                child.material.envMapIntensity = 0.4;
                                child.material.needsUpdate = true;
                            }
                        }
                    
                        // To Select Lights
                        if(child.isObject3D && child.name.includes('Area')){
                            //child.castShadow = true;  
                        }

                        // For Any Mesh Chold
                        if ( child.isMesh ) {
                        // ? TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
                        roughnessMipmapper.generateMipmaps( child.material );

                        //child.material.envMapIntensity = 1.2;
                        // For Lighting
                        child.receiveShadow = true;
                        child.castShadow    = true;

                        // For Geometry Collision
                        //sceneCollision.push(child);
                        }

                    } );

                    gltf.scene.name = "gallery";
                    scene.add( gltf.scene );

                    roughnessMipmapper.dispose();

                    divLoading.style.display = 'none';
                    divInstructions.hidden = false;
                    canvas.hidden = false;

                    // Refresh the Canvas
                    resizeCanvas();
                    renderer.render( scene, camera );

                    updatePoster();

                } ); // End Load the Gallery Model
                
                

                // Load the Virtual Gallery for Collision
                loader.load('gallery_collision_v2.glb', gltf => {
                    gltf.scene.traverse( function ( child ) {

                        // For Any Mesh Chold
                        if ( child.isMesh ) {
            
                        // For Geometry Collision
                        sceneCollision.push(child);
                        }
                } );
                
                gltf.scene.name = "gallery_collision";
                scene.add( gltf.scene );
                
                //renderer.render( scene, camera );

                // TODO For Debuging
                const pcollision   = scene.getObjectByName( "gallery_collision");
                pcollision.visible = false;
                meshCollision = pcollision;    

                });
                
        } );

        // Update the poster of the exhibit
        // updatePoster();

    } // End createSceneGallery() 




    /**
     * Update the Poster of the Exhibit
     */
    function updatePoster(){

        // Load the Poster Image of the exhibit
        let myUrl = pathAssets + "poster_default.png";

        console.log("Poster Exhibit: " , exhibit.exhibit);

        if(exhibit.exhibit)
            myUrl = exhibit.exhibit.poster

        if( myUrl === "" || myUrl === undefined )
            myUrl = pathAssets + "poster_default.png";

        console.log("Poster URL: " , myUrl);

        const textureLoader = new THREE.TextureLoader()
        textureLoader.crossOrigin = "Anonymous"
        const myTexture = textureLoader.load(myUrl)
        myTexture.flipY = false;

        // Get the Poster of the  Vitrina
        var object = scene.getObjectByName( "vitrina_poster" );
        console.log("vitrina_poster: " , object);

        // Assign the new poster
        if(object.material)
        {
            object.material.map = myTexture;
            console.log("assigned material ");
        }

        // Redraw the material
        object.material.needsUpdate = true


        // Get the Poster of the Billboard
        object = scene.getObjectByName( "Billboard_poster" );

        // Assign the new poster
        if(object.material)
            object.material.map = myTexture;

        // Redraw the material
        object.material.needsUpdate = true

        // Free Memory
        myTexture.dispose();
    }


    /**
     * 
     * @param {*} scene 
     */
    function createSceneExtra(scene){

        // Create a TV
        // if(!videoDiv)
        //     videoDiv = document.getElementById('videoTV');

        // videoDiv.src = '/assets/media/video.mp4';

        // let videoTexture = new THREE.VideoTexture(videoDiv);
        // videoDiv.loop = true;

        // // Create a Dummy box
        // const geometry = new THREE.BoxGeometry(1.5, 0.9, 0,1);
        // const material = new THREE.MeshStandardMaterial( { map: videoTexture } );
        // const tv       = new THREE.Mesh( geometry, material );
        // //scene.add( tv );
        // tv.geometry.rotateY(60*Math.PI/180);
        // tv.geometry.translate(14., 2, -1 );

        // Create the Floor
        let floorGeometry = new THREE.PlaneGeometry( 2000, 2000, 1, 1 );
            floorGeometry.rotateX( - Math.PI / 2 );

        const floorMaterial = new THREE.MeshStandardMaterial( 
            { color: 0xffffff, 
            flatShading: true  } );
            const floor = new THREE.Mesh( floorGeometry, floorMaterial );
        floor.position.y=-0.1;
        //cene.add( floor );

        // Create a Vision Line
        const geometry2 = new THREE.BoxGeometry(20, 0.01, 155);
        const material2 = new THREE.MeshStandardMaterial( { color: 0xff0000,
                                                            side: THREE.DoubleSide, 
                                                            transparent: true, 
                                                            opacity: 0.2} );
        const cube2     = new THREE.Mesh( geometry2, material2 );
        scene.add( cube2 );
        cube2.geometry.translate(5, 1.6, -5 );
        
        // TODO For Debuging
        cube2.visible = false;

        // Create Canvas
        //scene.add(createCanvas( {color: 'red', x: 5 , z:-5.5 }));

        // Create Ambient LIght
        const lightAmbient = new THREE.AmbientLight( 0xffffff ); // soft white light
        lightAmbient.intensity = 0.2;
        //scene.add( lightAmbient );  


        //Create a DirectionalLight and turn on shadows for the light
        const light = new THREE.DirectionalLight( 0xffffff, 1 );
        light.position.set( 8, 2, 10); //default; light shining from top
        light.castShadow = true; // default false
        light.target.position.set(8,0,-10);
        // scene.add( light );
        // scene.add( light.target );

        //Set up shadow properties for the light
        light.shadow.mapSize.width  = 1024; // default
        light.shadow.mapSize.height = 1024; // default
        light.shadow.camera.near    = 5; // default
        light.shadow.camera.far     = 100; // default
        light.shadow.radius = 5;

        // Create a Vision Line
        const spheGeo = new THREE.SphereGeometry(1, 16, 16);
        const spheMat = new THREE.MeshStandardMaterial( {   color: 0xff0000,
                                                            side: THREE.DoubleSide, 
                                                            transparent: true, 
                                                            opacity: 0.2} );
        const sphere     = new THREE.Mesh( spheGeo, spheMat );
        //scene.add( sphere );
        sphere.geometry.translate(8, 2, 10);

         //console.log("The Light")
         //console.log(light)

    } // End createSceneExtra

 
    
    /**
     * Create a beach and sun
     */
    function createSceneBeach(scene){

        //
        sun = new THREE.Vector3();

        // Watee
        const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );

        water = new Water(
            waterGeometry,
            {
                textureWidth: 512,
                textureHeight: 512,
                waterNormals: new THREE.TextureLoader().load( pathMedia + 'waternormals.jpg', function ( texture ) {

                    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

                } ),
                sunDirection: new THREE.Vector3(),
                sunColor: 0xffffff,
                waterColor: 0x001e0f,
                distortionScale: 1, //3.7,
                size: 2,
                fog: scene.fog !== undefined
            }
        );

        water.rotation.x = - Math.PI / 2;
        water.position.y = -0.32;
        scene.add( water );

    }


    /**
     * 
     */
    function populateExhibit(exhibit){

        groupCuadros = new THREE.Group();
        groupCuadros.name = "cuadros";

        if(!exhibit){
            console.log("La exposicion no existe");
            return groupCuadros;
        } 

        if(!exhibit.cuadros){
            console.log("La exposicion no tiene cuadros");
            return groupCuadros;
        } 

        if(exhibit.cuadros.length === 0)
        {
            console.log("La exposicion esta vacia");
            return groupCuadros;
        }
            
        
        // console.log("La exposicion tine " + exhibit.cuadros.length);
        exhibit.cuadros.forEach(cuadro => {
            let pcuadro = createCuadro(cuadro);
            groupCuadros.add(pcuadro);
        });
        
        scene.add(groupCuadros);
    }


    /**
     * TODO: Finish this function to create canvas 
     * @param {*} param0 
     * Titulo
     * Autor
     * Descripcion
     * Imagen
     * Tamaño (cm/inches)
     * Material (enums: Oleo, Lienzo)
     * url de la web
     * Posicion
     * Direccion
     * @returns 
     */
    function createCuadro(acuadro) {

        // Get the physical parameters 
        let image  = acuadro.imagen;     // Get the Image
        let height = acuadro.height/100; // Convert from cm to m
        let width  = acuadro.width/100;  // Convert from cm to m

        // Set the position of the canvas based of WallID Constants
        let posicion  = AppEnums.wallsList[acuadro?.wallID].center;
        let rotate    = AppEnums.wallsList[acuadro?.wallID].rotation;

        // Create the Texture with the provided image
        // const texture = new THREE.TextureLoader().load( pathCanvas + image );
        const texture = new THREE.TextureLoader().load( image );
        
        // Set the texture encoding to force to get accurate colors
        texture.encoding = THREE.sRGBEncoding;

        // Create the geometry of the canvas canvas
        const geometry = new THREE.BoxGeometry(width, height, 0.01 );

        // Create the material with the provided image texture
        const material = new THREE.MeshBasicMaterial({ map: texture });

        // Create the canvas mesh 
        const cube = new THREE.Mesh(geometry, material);

        // Set the canvas position in the gallery (the heigh is giving by the setting)
        cube.position.set(  posicion.x, 
                            referenceHeightCanva, 
                            posicion.z);

        // Enable to receive and generate shadows
        cube.castShadow    = true;
        cube.receiveShadow = true;
    
        // Apply Rotation to point to the normal of the placed wall
        cube.rotateY( rotate * Math.PI/180); // [rad]

        // Apply Scale Factor 
        cube.geometry.scale(acuadro.scalefactor, acuadro.scalefactor, 1);

        // Apply offset
        if(rotate === 90){
            cube.translateX(-acuadro.offset)
        } else if (rotate === 0 ){
            cube.translateX(acuadro.offset)
        } else if (rotate === 100 ){
            cube.translateX(acuadro.offset)
        }

        // Add all the attributes of acuadro to associate deatiled information to 
        // the generated canvas.
        cube.mydata = acuadro;

        // Enable hand cursos when the mouse is hovering the canvas, in this way 
        // we can inform the user the canvas is clickable. 
        cube.cursor = 'pointer';

        // Dispose the unnecesary textgure
        texture.dispose();

        // Return the new canvas
        return cube;
    }


    // ! Delete this
    function createDummyScene(){
        var geometry = new THREE.BoxGeometry( 1, 1, 1 );
        var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        var cube = new THREE.Mesh( geometry, material );
        scene.add( cube );
    } // End createDummyScene()

    
    let selectedObject = null;
    let showingObject  = null;

    function mouseMoveSelect(event) {

        // Sanity Check: The Tour has started
        if(!hasTourEnded)
            return;


        // Sanity Check: The Camera is not on rotation
        if(walkControl.mouseDragOn)
            return;

       // console.log("mouseMoveSelecting")
        var mouse = new THREE.Vector2();
        // mouse.x = + ( event.clientX / window.innerWidth  ) * 2 - 1;
        // mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        var rect = renderer.domElement.getBoundingClientRect();

		mouse.x = + ( ( event.clientX - rect.left ) / rect.width ) * 2 - 1;
		mouse.y = - ( ( event.clientY - rect.top ) / rect.height ) * 2 + 1;
    
        var raycaster = new THREE.Raycaster();
        raycaster.setFromCamera( mouse, camera );
        var intersects = raycaster.intersectObjects( groupCuadros.children, true );
    
        if(intersects.length > 0) {

            document.body.style.cursor = 'pointer';
            selectedObject = intersects[0].object;

        } else {
            document.body.style.cursor =  'grab'; //'default';
            selectedObject = null;
        }
    
    }
    
    

    function toggleInfoPanel(panel, selectedobject){

        //Sanity Check
        if(panel === undefined)
            return;

        // Sanity Check
        if(!selectedobject)
            return; 

        // Toggle the Panel (Show/Hide)
        if(panel.classList.contains('HidePanel'))
        {
            showingObject = selectedobject;
            populateInfoPanel(panel, showingObject.mydata)

            panel.classList.remove('HidePanel');
            panel.classList.add('ShowPanel');
        }
        else
        {
            if(showingObject === selectedobject)
            {
                panel.classList.remove('ShowPanel');
                panel.classList.add('HidePanel');
                showingObject = null;
            }else{
                
                showingObject = selectedobject;
                populateInfoPanel(panel, selectedobject.mydata)
            }
        }
    }

    /**
     * Hide the Info Panel
     * @param {*} panel The info pnale
     */
    function showInfoPanel(panel){

        //Sanity Check
        if(panel === undefined)
            return;

        panel.classList.remove('HidePanel');
        panel.classList.add('ShowPanel');
    }


    /**
     * Hide the Info Panel
     * @param {*} panel The info pnale
     */
    function hideInfoPanel(panel){

        //Sanity Check
        if(panel === undefined)
            return;

        showingObject = null;
        panel.classList.remove('ShowPanel');
        panel.classList.add('HidePanel');
    }


    /**
     * Populate the Info Panel with the selected canvas
     * @param {*} panel the panel Element
     * @param {*} data the canvas's data
     */
    function populateInfoPanel(panel, data){

        // document.getElementById("btnClose").removeEventListener('click', closeMe);
        let contentPanel = document.getElementById("ContentPanel");

        // Populate the Content of the panel with the selected portrait
        // let panelconent = 
        // `
        // <span class="title">` + data.titulo + `</span> 
        // <span class="author">` + data.autor + `</span>
        // <p class="desciption">` + data.descripcion + `</p>
        // <span class="size"><b>Tamaño: </b>` + data.height + "x" + data.width + `cm </span>
        // <span class="type"><b>Técnica: </b>` + data.material_es + `</span>
        // <span class="type"><b>Soporte: </b>` + data.canvas + `</span>
        // <span class="price"><b>Precio: </b>` + data.precio + `€ </span>
        // <a class="MuiButtonBase-root MuiButton-root MuiButton-contained btnShop" 
        //    tabindex="0" aria-disabled="false" href="` + data.url + `" 
        //    target="_blank"><span class="MuiButton-label">
        //    <span class="MuiButton-startIcon MuiButton-iconSizeMedium">
        //     <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" 
        //     aria-hidden="true">
        //     <path d="M21.9 8.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9l1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52L9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41-.25-.29-.37-.68-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5z"></path>
        //     </svg></span>  Comprar</span><span class="MuiTouchRipple-root"></span>
        // </a>
        // `;

        let englishOn = exhibit.englishOn;

        let panelconent = 
        `
        <span class="title">` + data?.title + `</span> 
        <span class="author">` + data?.author + `</span>
        <p class="desciption">` + ( englishOn ? data?.description_en : data?.description_es )+ `</p>
        <span class="size"><b>Tamaño: </b>` + data?.height + "x" + data.width + `cm </span>
        <span class="type"><b>Técnica: </b>` + ( englishOn ? AppEnums?.TECHNIQUES_LIST[data.technique]?.name_en : AppEnums?.TECHNIQUES_LIST[data.technique]?.name_es )  + `</span>
        <span class="type"><b>Soporte: </b>` + ( englishOn ? AppEnums?.MATERIALS_LIST[data.material]?.name_en   : AppEnums?.MATERIALS_LIST[data.material]?.name_es   )   + `</span>
        <span class="price"><b>Precio: </b>` + data?.price + `€ </span>
        <a class="MuiButtonBase-root MuiButton-root MuiButton-contained ` + (data?.sold ? "btnShopSold" : "btnShop") +  `" 
           tabindex="0" aria-disabled="false" href="` + data?.url + `" 
           target="_blank"><span class="MuiButton-label">
           <span class="MuiButton-startIcon MuiButton-iconSizeMedium">
            <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" 
            aria-hidden="true">
            <path d="M21.9 8.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9l1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52L9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41-.25-.29-.37-.68-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5z"></path>
            </svg></span>` + (data?.sold ? "Vendido" : "Comprar") +  `</span><span class="MuiTouchRipple-root"></span>
        </a>
        `;

        // Set the new html content
        contentPanel.innerHTML = panelconent;

    }


    /**
     * 
     * @param {*} cameracontrol 
     */
    function createMousePicking(cameracontrol){

        const geometry = new THREE.BufferGeometry();
        geometry.setFromPoints( [ new THREE.Vector3(), new THREE.Vector3() ] );

        line = new THREE.Line( geometry, new THREE.LineBasicMaterial() );
        scene.add( line );

        raycasterMouse = new THREE.Raycaster();

        mouseHelper = new THREE.Mesh( new THREE.BoxGeometry( 1, 1, 1 ), new THREE.MeshNormalMaterial() );
        mouseHelper.visible = false;
        scene.add( mouseHelper );

        cameracontrol.addEventListener( 'change', function () {
            moved = true;
            // debug DELETEME 
            // console.log( 'Listener :: change')
        } );

        window.addEventListener( 'pointerdown', function () {
            moved = false;
            // debug DELETEME 
            // console.log( 'Listener :: pointerdown')
        } );

        window.addEventListener( 'pointerup', function ( event ) {
           
            // debug DELETEME 
            // console.log( 'Listener :: pointerup')

            if ( moved === false ) {

                checkIntersection( event.clientX, event.clientY );

                //if ( intersection.intersects ) shoot();

            }

        } );

        window.addEventListener( 'pointermove', onPointerMove );

        function onPointerMove( event ) {

            // debug DELETEME 
            // console.log( 'Listener :: pointermove')

            if ( event.isPrimary ) {
                checkIntersection( event.clientX, event.clientY );
            }

        }

    }


    /**
     * Compute the Intersection with the mouse and the scene to compute the 
     * normal and intersection point. 
     * 
     * @param {*} x X-coordinate of the mouse
     * @param {*} y Y-coordinate of the mouse
     */
    function checkIntersection( x, y ) {

        // Sanity Check
        if ( meshCollision === undefined ) 
            return;

        mouse.x = + ( x / window.innerWidth  ) * 2 - 1;
        mouse.y = - ( y / window.innerHeight ) * 2 + 1;

        raycasterMouse.setFromCamera( mouse, camera );
        raycasterMouse.intersectObject( meshCollision, true, intersectsMouse );

        if ( intersectsMouse.length > 0 ) {

            // debug DELETEME 
            //console.log( 'CheckIntersection :: ' + intersectsMouse.length  + ' intersects')
            //console.info( intersectsMouse[ 0 ].object );

            const p = intersectsMouse[ 0 ].point;
            mouseHelper.position.copy( p );
            intersectionMouse.point.copy( p );

            const n = intersectsMouse[ 0 ].face.normal.clone();
            n.transformDirection( meshCollision.matrixWorld );
            n.multiplyScalar( .5 );
            n.add( intersectsMouse[ 0 ].point );

            intersectionMouse.normal.copy( intersectsMouse[ 0 ].face.normal);
            mouseHelper.lookAt( n );

            const positions = line.geometry.attributes.position;
            positions.setXYZ( 0, p.x, 1.7, p.z );
            positions.setXYZ( 1, n.x, 1.7, n.z );
            positions.needsUpdate = true;

            // if(clicked)
            // {
            //     console.log( 'x: ' + p.x + ', z: ' + p.z );
            // }

            intersectionMouse.intersects = true;

            intersectsMouse.length = 0;

        } else {

            intersectionMouse.intersects = false;

        }

    } // Enf checkIntersection


    /**
     * 
     */
    function bindEventListeners(){
        window.onresize = resizeCanvas;
        window.onmousemove = mouseMove;
        resizeCanvas();	

        // ! Check if this is working properly or no. It seems that is not working
        // ! at all. 
        window.addEventListener( 'unload', onLoad, false );
    } // End bindEventListeners()



    /**
     * Click Callback for the Navigation Instruction Container
     */
    function bindStartTourListener(){

        divInstructions.style.display = 'none';
        divBlocker.style.display      = 'none';
        walkControl.activeLook        = false;
    
        // Start the Video on the Virtual TV
        // if(videoDiv)
        //   videoDiv.play();
    
        // Start Position 
        const coords = {x: 5.816012029169941, y: 2.528875540357396} 
        new TWEEN.Tween(coords)
          .to({ x: 5.616012029169941, y: -0.8 }, 4000)  // Final Position
          .easing(TWEEN.Easing.Quadratic.Out)
          .onUpdate(() =>
            camera.position.set(coords.x, 1.7,coords.y)
          )
          .onComplete(()=>{
            walkControl.activeLook = true;
            hasTourEnded = true; 
          })
          .start();
    } // End bindStartTourListener()


    /**
     * Callback When the Document has been loaded or reloaded to 
     * dipose the rendered and avoid memory overflow
     */
    function onLoad(){
        // console.log("Dipossing....")
        // Sanity check: If renderer has been instanciated 
        if(renderer) renderer.dispose();
    
    } // End onLoad()


    /**
     * Resize Callback function
     */
    function resizeCanvas(){

        canvas.style.width = '84vw';
        canvas.style.height= '66vh';
        
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        const width  = canvas.width;
        const height = canvas.height;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        
        renderer.setSize(width, height);

        if(walkControl)
            walkControl.handleResize();

    }


    /**
     * 
     * @param {*} param0 
     */
    function mouseMove({screenX, screenY}){
        // Nothing to do here yet 
        // ! Delete this to clean up
    }   


    /**
     * Render Callback Function
     * @param {*} time 
     */
    function render(time){
        
        // Request Animation Frame
        requestAnimationFrame(render);

        // Get the Delta time 
        const delta = clock.getDelta();

        // Update the Camera Controller
        if(walkControl)
            walkControl.update( delta );

        // Update de Water
        if(water)
        {
            water.material.uniforms[ 'time' ].value += (1.0 / 90);
        }

        // Render the Scene
        renderer.render(scene, camera);

        // Update the TWEEN animation
        TWEEN.update(time);

    }


    /**
     * To Optimize the use of CPU when the page is not being displayed
     * ref: https://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
     *
     * TODO: Validate that this method works also on react, if not
     * fint a similar procedure for React   
     */
    function RunOnDisplayOnly(){

        var hidden = "hidden";
    
        // Standards:
        if (hidden in document)
        document.addEventListener("visibilitychange", onchange);
        else if ((hidden = "mozHidden") in document)
        document.addEventListener("mozvisibilitychange", onchange);
        else if ((hidden = "webkitHidden") in document)
        document.addEventListener("webkitvisibilitychange", onchange);
        else if ((hidden = "msHidden") in document)
        document.addEventListener("msvisibilitychange", onchange);
        // IE 9 and lower:
        else if ("onfocusin" in document)
        document.onfocusin = document.onfocusout = onchange;
        // All others:
        else
        window.onpageshow = window.onpagehide
        = window.onfocus = window.onblur = onchange;
    
        function onchange (evt) {
        var v = "visible", h = "hidden",
            evtMap = {
                focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
            };
    
        evt = evt || window.event;
        if (evt.type in evtMap)
            document.body.className = evtMap[evt.type];
        else
            document.body.className = this[hidden] ? "hidden" : "visible";
        }
    
        // set the initial state (but only if browser supports the Page Visibility API)
        if( document[hidden] !== undefined )
        onchange({type: document[hidden] ? "blur" : "focus"});

    }


    function destroy(){
        console.log("Destroying from inside");

    }

}